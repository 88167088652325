import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sin-datos',
  template: `
  <ion-card style="text-align: center; padding: 30px; margin-bottom: 20px">
    {{ texto }}
  </ion-card>
  `
})

export class SinDatosComponent implements OnInit {

  @Input() texto: string;

  constructor() { }

  ngOnInit() { }

}
