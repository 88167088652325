import { NgModule, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
import { IonicModule } from '@ionic/angular';
import { BtnRegresarComponent } from './btn-regresar/btn-regresar.component';
import { SinDatosComponent } from './sin-datos/sin-datos.component';
@NgModule({
  declarations: [
    NavbarComponent,
    BtnRegresarComponent,
    SinDatosComponent
  ],
  exports: [
    NavbarComponent,
    BtnRegresarComponent,
    SinDatosComponent
  ],
  imports: [
    CommonModule,
    IonicModule
  ]
})
export class ComponentsModule {

}
